import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';

import {HttpClient, HttpClientModule} from '@angular/common/http';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {CustomTranslateLoader} from './providers/translate-loader/CustomTranslateLoader';

import {ToastModule} from 'primeng/toast';
import {IntercomModule} from 'ng-intercom';
import {registerLocaleData} from '@angular/common';

import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import {EdwMissingTranslationHandler, EdwToolsLocaleService} from '@edward-software/edw-fwk-angular-lib/providers';
import {EdwFwkAngularLibModule} from '@edward-software/edw-fwk-angular-lib/api';
import {RouterModule} from '@angular/router';
import {environment} from '../environments/environment';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ToastModule,
        RouterModule,
        FormsModule,
        EdwFwkAngularLibModule.forRoot(environment),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomTranslateLoader,
                deps: [HttpClient]
            },
            missingTranslationHandler: {provide: MissingTranslationHandler, useClass: EdwMissingTranslationHandler}
        }),
        IntercomModule.forRoot({
            appId: 't1jxdwi9',
            updateOnRouterChange: true
        }),
        HttpClientModule,
        AppRoutingModule,
    ],
    providers: [
        {
            provide: LOCALE_ID,
            deps: [EdwToolsLocaleService],
            useFactory: (localeService: { locale: string }) => localeService.locale
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

registerLocaleData(localeEn);
registerLocaleData(localeFr);
