import {TranslateLoader} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {EnvConfig} from '@edward-software/edw-fwk-angular-lib/models';
import {environment} from '../../../environments/environment';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
    envConfig: EnvConfig;
    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient) {
        this.envConfig = JSON.parse(localStorage.getItem('ENV_CONFIG'));
    }

    /**
     * Fonction de l'interface TranslateLoader
     * Récupère toutes les translations depuis getAllTranslations(), les fusionne et retourne un objet translation
     */
    getTranslation(lang: string): Observable<any> {
        return this.getAllTranslations(lang)
            .pipe(map(
                resp => {
                    let obj = {};
                    resp.forEach(trad => {
                        if (trad) {
                            obj = Object.assign(obj, trad);
                        }
                    });
                    return obj;
                },
            ));
    }

    /**
     * On définit une requête GET pour chaque tranlsation que l'on souhaite récupérer
     * forkJoin() envoie toutes les requêtes en parallèle et renvoie UN SEUL Observable contenant un tableau des réponses de chaque reqûete
     */
    getAllTranslations(lang: string): Observable<any[]> {


        const commonUrl = this.envConfig.apiTranslationUrl + '/translations/file/common/' + lang;
        const workflowUrl = this.envConfig.apiTranslationUrl + '/translations/file/workflow/' + lang;

        let commonRequest = this.http.get(commonUrl, this.options)
            .pipe(
                catchError(e => {
                    return this.http.get(`assets/i18n/common_${lang}.json`);
                }));
        let workflowRequest = this.http.get(workflowUrl, this.options)
            .pipe(
                catchError(e => {
                    return this.http.get(`assets/i18n/workflow_${lang}.json`);
                }));

        if (environment.production === false) {
            commonRequest = this.http.get(`./assets/i18n/common_${lang}.json`);
            workflowRequest = this.http.get(`./assets/i18n/workflow_${lang}.json`);
        }

        return forkJoin([commonRequest, workflowRequest]);

    }
}
