import {Component, OnInit} from '@angular/core';

import {MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {
    EdwHeaderNavbarService,
    EdwToolsToastService,
    EdwToolsWorkspaceService
} from '@edward-software/edw-fwk-angular-lib/providers';
import {CommonAppComponent} from '@edward-software/edw-fwk-angular-lib/common-app';
import {environment} from '../environments/environment';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [MessageService]
})
export class AppComponent extends CommonAppComponent implements OnInit {
    constructor(
        route: ActivatedRoute,
        router: Router,
        translate: TranslateService,
        messageService: MessageService,
        workspaceService: EdwToolsWorkspaceService,
        headerNavbarService: EdwHeaderNavbarService,
        toastService: EdwToolsToastService
    ) {
        super(
            route,
            router,
            translate,
            messageService,
            workspaceService,
            headerNavbarService,
            toastService,
            environment
        );
    }
}

