import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard, ResponsiveGuard} from '@edward-software/edw-fwk-angular-lib/providers';

const routes: Routes = [
    {
        path: 'w/:workspace',
        canActivate: [AuthGuard, ResponsiveGuard],
        loadChildren: () => import('./components/layouts/secure/secure.module').then(m => m.SecureModule)
    },
    {
        path: '',
        loadChildren: () => import('./components/layouts/public/public.module').then(m => m.PublicModule)
    }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})]
})
export class AppRoutingModule {
}
